/*
 * @Author: Leeon 282276848@qq.com
 * @Date: 2024-03-28 16:39:34
 * @LastEditors: Leeon 282276848@qq.com
 * @LastEditTime: 2024-03-28 17:43:29
 * @FilePath: \jirh-h5\src\mixinx\applyBus.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import * as API_Common from "@/api/common.js";
import { setEncryption } from "@/utils/encrypt"; //引入加密文件
export const applyBus = {
  methods: {
    // step1 表单提交
    async handleSubmit() {
      if (this.form.realName === "") {
        this.$toast("请输入姓名");
        this.$refs.container.scrollTo({
          top: 0,
          behavior: "smooth",
        });
        return;
      } else if (this.form.idCard === "") {
        this.$toast("请输入身份证号码");
        this.$refs.container.scrollTo({
          top: 0,
          behavior: "smooth",
        });
        return;
      } else if (!this.regTest.regIdcard.test(this.form.idCard)) {
        this.$toast("请输入正确的身份证号码");
        this.$refs.container.scrollTo({
          top: 0,
          behavior: "smooth",
        });
        return;
      } else if (this.form.credit === "") {
        this.$toast("请选择芝麻分");
        this.$refs.container.scrollTo({
          top: this.$refs.credit.offsetTop,
          behavior: "smooth",
        });
        return;
      }

      this.formItemData.creditType.map((i) => {
        if (i.active) {
          this.form.assets.push(i.dictValue);
        }
      });

      if (this.form.assets.length === 0) {
        this.$toast("请至少选择一项资产");
        return;
      }

      if (this.form.antCreditPay === "") {
        this.$toast("请选择蚂蚁花呗");
        return;
      } else if (this.form.jdIous === "") {
        this.$toast("请选择京东白条");
        return;
      } else if (!this.positionStatus && this.form.cityId === "") {
        this.$toast("请选择您所在的城市");
        return;
      } else if (!this.checked) {
        this.$toast("请勾选同意授权及告知书");
        return;
      }

      // 按钮loading
      this.loading = true;

      let datas = {
        ...this.form,
        ...{
          realName: setEncryption(this.form.realName),
          idCard: setEncryption(this.form.idCard),
        },
      };

      // 日志参数
      let logData = {
        applyId: null,
        channel: this.form.channelNo,
        pageUrl: window.location.href,
        errorType: null,
        applySource: 2,
        errorMsg: null,
      };
      console.log(window.location.href);
      try {
        let res = await API_Common.submitAnEntry(datas);
        //埋点数据的值
        logData.errorType = 1;
        logData.applyId = res?.data?.applyId;
        logData.errorMsg = res?.msg;
        if (res?.code === 200) {
          // 取消按钮loading
          this.loading = false;

          // 进件申请ID赋值(applyId如果为0则代表进件失败，否则代表成功
          // 1: 贷超流程，失败了要跳转贷超页面
          // 2: 贷超流程，成功了要跳转进件完成页面(并且结果页面按钮展示为<我知道了>),点击我知道了跳转贷超页面
          //进件ID
          this.applyId = res.data.applyId;
          // 产品ID
          this.productId = res.data.productId;
          // 是否推送成功;
          // 1: 贷超流程，失败了要跳转贷超页面
          // 2: 贷超流程，成功了要跳转进件完成页面(并且结果页面按钮展示为<我知道了>),点击我知道了跳转贷超页面
          this.sendData = res.data; //发送给协议弹窗页面
          this.hasPushSuccess = res.data.hasPushSuccess;
          this.applyTime = res.data.applyTime;
          // 重定向页面地址
          this.redirectUrl = res.data.redirectUrl;

          this.incomingShow = true;
          if (this.applyId !== 0 && this.hasPushSuccess) {
            this.companys = res.data.apiNames.join("、");
            this.companyAlias = res.data.apiAlias;
          } else {
            this.companys = "";
          }
        } else if (
          res?.code === 1109 ||
          res?.code === 1110 ||
          res?.code === 1004 ||
          res?.code === 1005 ||
          res?.code === 1006
        ) {
          // 取消按钮loading
          this.loading = false;
          //添加埋点
          API_Common.addMatchStayLog(logData);
          // 区分贷超流程以及非贷超流程
          // 1109:进件的时候手机号是同一个身份证是同一个重复提交-跳转红包下载页面
          // 1110: 此处判断为进件重复(规则是进件的时候身份证是一个但是手机号不同的情况-跳转红包下载页面)
          this.$toast(res.msg);
        } else {
          this.loading = false;
          // 添加埋点
          API_Common.addMatchStayLog(logData);
          //需要跳转的页面
          this.gotoNextPage(false);
          this.$toast(res.msg);
        }
      } catch (error) {
        this.requestErrorHandle(error, logData);
      }
    },

    // step1 表单提交
    async handleSubmitTl3() {
      if (this.form.realName === "") {
        this.$toast("请输入姓名");
        this.$refs.container.scrollTo({
          top: 0,
          behavior: "smooth",
        });
        return;
      } else if (this.form.idCard === "") {
        this.$toast("请输入身份证号码");
        this.$refs.container.scrollTo({
          top: 0,
          behavior: "smooth",
        });
        return;
      } else if (!this.regTest.regIdcard.test(this.form.idCard)) {
        this.$toast("请输入正确的身份证号码");
        this.$refs.container.scrollTo({
          top: 0,
          behavior: "smooth",
        });
        return;
      } else if (this.form.credit === "") {
        this.$toast("请选择芝麻分");
        this.$refs.container.scrollTo({
          top: this.$refs.credit.offsetTop,
          behavior: "smooth",
        });
        return;
      } else if (this.assetFromJS.house === "") {
        this.$toast("请选择房产信息");
        this.$refs.container.scrollTo({
          top: this.$refs.credit.offsetTop,
          behavior: "smooth",
        });
        return;
      } else if (this.assetFromJS.car === "") {
        this.$toast("请选择车产信息");
        this.$refs.container.scrollTo({
          top: this.$refs.credit.offsetTop,
          behavior: "smooth",
        });
        return;
      } else if (this.assetFromJS.accumulation === "") {
        this.$toast("请选择公积金");
        this.$refs.container.scrollTo({
          top: this.$refs.credit.offsetTop,
          behavior: "smooth",
        });
        return;
      } else if (this.assetFromJS.social === "") {
        this.$toast("请选择社保");
        this.$refs.container.scrollTo({
          top: this.$refs.credit.offsetTop,
          behavior: "smooth",
        });
        return;
      } else if (this.assetFromJS.insurance === "") {
        this.$toast("请选择商业保险");
        this.$refs.container.scrollTo({
          top: this.$refs.credit.offsetTop,
          behavior: "smooth",
        });
        return;
      } else if (this.assetFromJS.profession === "") {
        this.$toast("请选择职业信息");
        this.$refs.container.scrollTo({
          top: this.$refs.credit.offsetTop,
          behavior: "smooth",
        });
        return;
      }

      if (this.form.antCreditPay === "") {
        this.$toast("请选择蚂蚁花呗");
        return;
      } else if (this.form.jdIous === "") {
        this.$toast("请选择京东白条");
        return;
      } else if (!this.positionStatus && this.form.cityId === "") {
        this.$toast("请选择您所在的城市");
        return;
      }

      this.setAssetArray();
      if (this.form.assets.length === 0) {
        this.$toast("请至少选择一项资产");
        return;
      }

      // // 按钮loading
      this.loading = false;
      let datas = {
        ...this.form,
        ...{
          realName: setEncryption(this.form.realName),
          idCard: setEncryption(this.form.idCard),
        },
      };
      // 日志参数
      let logData = {
        applyId: null,
        channel: this.form.channelNo,
        pageUrl: window.location.href,
        errorType: null,
        applySource: 2,
        errorMsg: null,
      };
      try {
        let res = await API_Common.saveApplyData(datas);
        //埋点数据的值
        logData.errorType = 1;
        logData.errorMsg = res?.msg;
        if (res?.code === 200) {
          // 跳转到匹配中页面
          this.$router.push({
            name: "MatchWaiting",
            query: {
              source: this.form.channelNo,
              orderSn: res.data.orderSn,
              brand: this.brand,
              amount: this.form.loanAmount,
              downPageUrl: this.downPageUrl,
              isRedirectProductsPage: this.isRedirectProductsPage,
              isRedirectWorkWeixinPage: this.isRedirectWorkWeixinPage,
            },
          });
        } else if (
          res?.code === 1110 ||
          res?.code === 1004 ||
          res?.code === 1005 ||
          res?.code === 1006
        ) {
          // 取消按钮loading
          this.loading = false;
          //添加埋点
          API_Common.addMatchStayLog(logData);
          this.$toast(res.msg);
        } else {
          this.loading = false;
          // 添加埋点
          API_Common.addMatchStayLog(logData);
          //需要跳转的页面
          this.gotoNextPage(false);
          this.$toast(res.msg);
        }
      } catch (error) {
        this.requestErrorHandle(error, logData);
      }
    },

    // step1 表单提交
    async handleSubmitTl7() {
      if (this.form.realName === "") {
        this.$toast("请输入姓名");
        this.$refs.container.scrollTo({
          top: 0,
          behavior: "smooth",
        });
        return;
      }
      if (this.form.idCard === "") {
        this.$toast("请输入身份证号码");
        this.$refs.container.scrollTo({
          top: 0,
          behavior: "smooth",
        });
        return;
      }
      if (!this.regTest.regIdcard.test(this.form.idCard)) {
        this.$toast("请输入正确的身份证号码");
        this.$refs.container.scrollTo({
          top: 0,
          behavior: "smooth",
        });
        return;
      }
      if (this.form.credit === "") {
        this.$toast("请选择芝麻分");
        this.$refs.container.scrollTo({
          top: this.$refs.credit.offsetTop,
          behavior: "smooth",
        });
        return;
      }
      if (this.assetFromJS.house === "") {
        this.$toast("请选择房产信息");
        this.$refs.container.scrollTo({
          top: this.$refs.credit.offsetTop,
          behavior: "smooth",
        });
        return;
      }
      if (this.assetFromJS.car === "") {
        this.$toast("请选择车产信息");
        this.$refs.container.scrollTo({
          top: this.$refs.credit.offsetTop,
          behavior: "smooth",
        });
        return;
      }

      if (this.assetFromJS.social === "") {
        this.$toast("请选择社保");
        this.$refs.container.scrollTo({
          top: this.$refs.credit.offsetTop,
          behavior: "smooth",
        });
        return;
      }

      if (this.assetFromJS.accumulation === "") {
        this.$toast("请选择公积金");
        this.$refs.container.scrollTo({
          top: this.$refs.credit.offsetTop,
          behavior: "smooth",
        });
        return;
      }

      if (this.assetFromJS.insurance === "") {
        this.$toast("请选择商业保险");
        this.$refs.container.scrollTo({
          top: this.$refs.credit.offsetTop,
          behavior: "smooth",
        });
        return;
      }
      if (this.assetFromJS.profession === "") {
        this.$toast("请选择职业信息");
        this.$refs.container.scrollTo({
          top: this.$refs.credit.offsetTop,
          behavior: "smooth",
        });
        return;
      }

      this.setAssetArray();
      if (this.form.assets.length === 0) {
        this.$toast("请至少选择一项资产");
        return;
      }

      // 按钮loading
      this.loading = true;

      let datas = {
        ...this.form,
        ...{
          realName: setEncryption(this.form.realName),
          idCard: setEncryption(this.form.idCard),
        },
      };

      // 日志参数
      let logData = {
        applyId: null,
        channel: this.form.channelNo,
        pageUrl: window.location.href,
        errorType: null,
        applySource: 2,
        errorMsg: null,
      };
      try {
        let res = await API_Common.submitAnEntry(datas);
        //埋点数据的值
        logData.errorType = 1;
        logData.applyId = res?.data?.applyId;
        logData.errorMsg = res?.msg;
        if (res?.code === 200) {
          // 取消按钮loading
          this.loading = false;

          // 进件申请ID赋值(applyId如果为0则代表进件失败，否则代表成功
          // 1: 贷超流程，失败了要跳转贷超页面
          // 2: 贷超流程，成功了要跳转进件完成页面(并且结果页面按钮展示为<我知道了>),点击我知道了跳转贷超页面
          //进件ID
          this.applyId = res.data.applyId;
          // 产品ID
          this.productId = res.data.productId;
          // 是否推送成功;
          // 1: 贷超流程，失败了要跳转贷超页面
          // 2: 贷超流程，成功了要跳转进件完成页面(并且结果页面按钮展示为<我知道了>),点击我知道了跳转贷超页面
          this.sendData = res.data; //发送给协议弹窗页面
          this.hasPushSuccess = res.data.hasPushSuccess;
          this.applyTime = res.data.applyTime;
          // 重定向页面地址
          this.redirectUrl = res.data.redirectUrl;

          this.incomingShow = true;
          if (this.applyId !== 0 && this.hasPushSuccess) {
            this.companys = res.data.apiNames.join("、");
            this.companyAlias = res.data.apiAlias;
          } else {
            this.companys = "";
          }
        } else if (
          res?.code === 1109 ||
          res?.code === 1110 ||
          res?.code === 1004 ||
          res?.code === 1005 ||
          res?.code === 1006
        ) {
          // 取消按钮loading
          this.loading = false;
          //添加埋点
          API_Common.addMatchStayLog(logData);
          // 区分贷超流程以及非贷超流程
          // 1109:进件的时候手机号是同一个身份证是同一个重复提交-跳转红包下载页面
          // 1110: 此处判断为进件重复(规则是进件的时候身份证是一个但是手机号不同的情况-跳转红包下载页面)
          this.$toast(res.msg);
        } else {
          this.loading = false;
          // 添加埋点
          API_Common.addMatchStayLog(logData);
          //需要跳转的页面
          this.gotoNextPage(false);
          this.$toast(res.msg);
        }
      } catch (error) {
        this.requestErrorHandle(error, logData);
      }
    },

    // step2 提交表单后的下一步页面跳转
    async submitPushApply() {
      // 有重定向 直接跳转
      if (this.redirectUrl) {
        // 跳转到三方页面
        window.open(this.redirectUrl);
        return;
      }

      // 匹配失败
      if (this.hasPushSuccess === 0) {
        this.gotoNextPage(false);
        return;
      }

      this.loading = true;

      // 调用推送进件接口
      try {
        var res = await API_Common.pushApply({
          applyId: this.applyId,
          productId: this.productId,
        });
        this.loading = false;
        if (res.code === 200) {
          const hasPushSucces = res.data.hasPushSuccess;
          this.gotoNextPage(hasPushSucces);
        } else {
          this.gotoNextPage(false);
        }
      } catch (err) {
        this.loading = false;
        this.gotoNextPage(false);
      }
    },
    //跳转下一个页面
    gotoNextPage(hasPushSuccess) {
      if (hasPushSuccess) {
        var timestamp = new Date().getTime();
        // 跳转到成功结果页, 不管什么贷超流程
        window.location.href =
          `${this.downPageUrl}?source=${this.form.channelNo}&brand=${this.brand}&amount=${this.form.loanAmount}&applyId=${this.applyId}&isRedirectProductsPage=${this.isRedirectProductsPage}` +
          "&tsp=" +
          timestamp;
        return;
      }

      if (this.isRedirectProductsPage === 1) {
        // 跳转到贷超
        setTimeout(() => {
          var timestamp = new Date().getTime();
          window.location.href =
            `/productList.html?source=${this.form.channelNo}` +
            "&brand=" +
            this.brand +
            "&tsp=" +
            timestamp;
        }, 1500);
      } else {
        // 跳转到失败结果页
        // 非贷超情况跳转兜底下载页面
        setTimeout(() => {
          this.$router.push({
            name: "TrialSuccessTl9",
            query: {
              source: this.form.channelNo,
              loanAmount: this.form.loanAmount,
            },
          });
        }, 1500);
      }
    },
    // 我已知晓下一步
    handleGoOn() {
      if (this.loading) {
        return;
      }
      this.submitPushApply();
    },

    // 匹配报错处理
    requestErrorHandle(error, logData) {
      // 添加埋点
      delete logData.applyId;
      logData.errorType = 2;
      logData.errorMsg = error.toString();
      this.$toast({
        message: "当前申请人数较多，请重新提交获取结果",
        duration: 3000,
      });
      setTimeout(() => {
        this.loading = false;
        API_Common.addMatchStayLog(logData);
        this.gotoNextPage(false);
      }, 3000);
    },

    // 处理页面加载完成
    handleLoadFinish(pid) {
      let logData = {
        channel: this.form.channelNo,
        pageUrl: window.location.href,
        tsp: this.$route.query.tsp,
        pid: pid,
      };
      API_Common.applyStayMonitor(logData);
    },
  },
};
